import React from "react";
import {
  FaEnvelopeOpenText,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaRegCalendarAlt,
} from "react-icons/fa";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import aboutImage from "../../assets/images/about/about.jpg";
import "../../Share/Style.css";
import Footer from "../../Share/Footer";
import UseData from "../../Hooks/UseData";
import SliderCommon from "../../Share/SliderCommon";
import AboutCard from "./AboutCard";
import PageTitle from "../../Share/PageTitle";

const About = () => {
  const { local, serviceArray } = UseData();
  return (
    <>
      <PageTitle title="About"></PageTitle>
      {/* End pagetitle */}

      <section>
        <div className="container lg:rounded-2xl bg-white dark:bg-[#111111]  px-4 sm:px-5 md:px-10 lg:px-20">
          <div data-aos="fade">
            <div className="py-12">
              {/* Page Title */}
              <h2 className="after-effect after:left-52 mt-12 lg:mt-0 ">
                About Me
              </h2>
              <div className="grid grid-cols-12 md:gap-10 pt-4 md:pt-[40px] items-center">
                <div className="col-span-12 md:col-span-4">
                  {/* personal images for about page  */}
                  <img
                    className="w-full md:w-[330px] md:h-[400px] object-cover overflow-hidden rounded-[35px] mb-3 md:mb-0"
                    src={aboutImage}
                    alt=""
                  />
                </div>
                <div className="col-span-12 md:col-span-8 space-y-2.5">
                  {/* About me information */}
                  <div className=" md:mr-12 xl:mr-16">
                    <h3 className="text-4xl font-medium dark:text-white mb-2.5 ">
                      Who am I?
                    </h3>
                    <p className="text-gray-lite dark:text-color-910 leading-7">

Hello, I'm Shauni 👋, a dedicated full-stack developer with a unique journey that began in the hospitality industry. After many successful years in that dynamic field, I decided to pursue my true passion for programming and enrolled in a transformative 26-week full-stack boot camp at <a href="https://www.codeop.tech/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">CodeOp</a>🌈
  <br/><br/>
  During this intensive program, I immersed myself in the exhilarating world of application development, exploring the limitless possibilities offered by cutting-edge technologies such as React.js, Vue, Node.js, Express, MySQL, Git, and Heroku. This immersive experience has equipped me with the skills to create websites, mobile apps, and robust back-end environments, allowing me to bring your visions to life.
  <br/><br/>
  Web design and development is where my heart truly lies. I am deeply committed to developing elegant, simple, and optimized web designs that not only captivate users but also deliver seamless and delightful experiences. The intersection of creativity and technology is where I thrive, and I take pride in crafting user interfaces that are visually stunning and highly functional.
  <br/><br/>
  I believe in creating applications with love and care, ensuring that each project reflects the passion and dedication I have for my craft. Whether it's a small website or a complex web application, I approach every task with the utmost attention to detail and a focus on delivering excellence.
  <br/><br/>
  If you are interested in working with me or collaborating on exciting projects, I welcome you to reach out and drop me a message. I am always open to new opportunities and look forward to exploring ways we can create something extraordinary together.
  💻💖

</p>


                    <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">

                    </p>

                    <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
              
                    </p>
                  </div>

                  {/* personal information */}
                  <div>
                    <h3 className="text-4xl font-medium my-5 dark:text-white">
                      Personal Info
                    </h3>
                    <div className=" grid grid-cols-1 sm:grid-cols-2 gap-6">
                      <div className="flex">
                        <span className="text-oriange dark:bg-color-990 shadow-icon mr-2.5 flex items-center justify-center rounded-md text-2xl w-12 text-">
                          <FaMobileAlt />
                        </span>
                        <div className="space-y-1">
                          <p className="text-xs text-gray-lite dark:text-color-910">
                            Phone
                          </p>
                          <h6 className="font-medium dark:text-white">
                            <a
                              className="hover:text-[#FA5252] duration-300 transition"
                              href="tel:+1234567890"
                            >
                              +44 7469189422
                            </a>
                          </h6>
                        </div>
                      </div>
                      <div className="flex">
                        <span className="text-oriange-lite dark:bg-color-990 shadow-icon mr-2.5 flex items-center justify-center rounded-md text-2xl w-12 text-">
                          <FaMapMarkerAlt />
                        </span>
                        <div className="space-y-1">
                          <p className="text-xs text-gray-lite dark:text-color-910">
                            Location
                          </p>
                          <h6 className="font-medium dark:text-white">
                            Brighton
                          </h6>
                        </div>
                      </div>
                      <div className="flex">
                        <span className="text-green dark:bg-color-990 shadow-icon mr-2.5 flex items-center justify-center rounded-md text-2xl w-12 text-">
                          <FaEnvelopeOpenText />
                        </span>
                        <div className="space-y-1">
                          <p className="text-xs text-gray-lite dark:text-color-910">
                            Email
                          </p>
                          <h6 className="font-medium dark:text-white">
                            <a
                              className="hover:text-[#FA5252] duration-300 transition"
                              href="shaunivanderhorst@gmail.com"
                            >
                              shaunivanderhorst@gmail.com
                            </a>
                          </h6>
                        </div>
                      </div>
                  
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End personal information */}
                </div>


            <div className="  pb-12 ">
              <h3 className="text-[35px] dark:text-white font-medium pb-5">
                {/* What I do! */}
              </h3>
              <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 ">
                {/* Experience information  */}

                {serviceArray.map((item, i) => (
                  <AboutCard key={i} item={item} local={local} />
                ))}
              </div>
            </div>

            <div>
              {/* Slick Slider call here  */}
              <SliderCommon />
            </div>

            {/* Common Footer call here */}
            <Footer condition={false} bg={"#FFFF"} />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
